import './text-image-slider.scss';
import Swiper, { Navigation, Pagination } from 'swiper';

class TextImageSlider {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-textimageslider',
            sliderSuffix: 'slider',
            slideSuffix: 'slide',
            controlsSuffix: 'controls'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);

        // Elements
        this.slider = null;

        // Elements
        this.$textImageSlider = element;
        this.$textImageSliderSlider = this.$textImageSlider.querySelector(`[${this.settings.initAttr}="${this.settings.sliderSuffix}"]`);
        this.$textImageSliderControls = this.$textImageSlider.querySelector(`[${this.settings.initAttr}="${this.settings.controlsSuffix}"]`);
    }

    initialize () {
        if (this.$textImageSliderSlider && this.$textImageSliderControls) {
            this.initSlider();
        }
    }

    initSlider () {
        this.slider = new Swiper(this.$textImageSliderSlider, {
            modules: [Navigation, Pagination],
            speed: 500,
            effect: 'fade',
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            autoHeight: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    }
}

export { TextImageSlider };
