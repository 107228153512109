import './accordion.scss';

class Accordion {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-accordion',
            elementAttr: 'element',
            triggerAttr: 'trigger',
            contentAttr: 'content',
            activeClassname: 'is--active',
            speed: 0.25,
            toggle: true
        };

        this.settings = Object.assign({}, settings, options);
        this.$accordion = element;
        this.$accordionElements = this.$accordion.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.elementAttr + '"]');

        this.triggerEvent = (e) => {
            const $trigger = e.target.closest('[' + this.settings.initAttr + '="' + this.settings.triggerAttr + '"]');
            if ($trigger) {
                const $element = $trigger.closest('[' + this.settings.initAttr + '="' + this.settings.elementAttr + '"]');
                if (this.settings.toggle === true) {
                    this.toggleContent($element);
                } else {
                    this.setActiveContent($element);
                }
            }
        };
    }

    initialize () {
        this.$accordionElements.forEach(($element) => {
            this.toggleFocusElements($element, 'disable');
        });
        this.setEvents(true);
    }

    toggleFocusElements ($element, status) {
        const $focusableElements = $element.querySelectorAll('a[href], button, textarea, input, select');
        $focusableElements.forEach((element) => {
            element.setAttribute('tabindex', status === 'enable' ? '0' : '-1');
        });
    }

    setEvents (active) {
        if (active === true) {
            this.$accordionElements.forEach((element) => {
                element.firstElementChild.addEventListener('keydown', (e) => {
                    if (e.keyCode === 13 || e.keyCode === 32) {
                        e.preventDefault();
                        this.toggleContent(element);
                    }
                });
            });
            this.$accordion.addEventListener('click', this.triggerEvent, false);
        } else {
            this.$accordion.removeEventListener('click', this.triggerEvent, false);
        }
    }

    toggleContent ($accordionElement) {
        if ($accordionElement.classList.contains(this.settings.activeClassname)) {
            this.closeContent($accordionElement);
        } else {
            this.openContent($accordionElement);
        }
    }

    setActiveContent ($accordionElement) {
        this.$accordionElements.forEach($currentElement => {
            if ($currentElement !== $accordionElement) {
                this.closeContent($currentElement);
            } else {
                this.toggleContent($accordionElement);
            }
        });
    }

    openContent ($accordionElement) {
        const $content = $accordionElement.querySelector('[' + this.settings.initAttr + '="' + this.settings.contentAttr + '"]');
        const $trigger = $accordionElement.querySelector('[' + this.settings.initAttr + '="' + this.settings.triggerAttr + '"]');

        if ($content && $trigger) {
            $accordionElement.classList.add(this.settings.activeClassname);
            $content.style.height = 'auto';
            const height = $content.clientHeight + 'px';
            $content.style.height = '0px';

            setTimeout(() => {
                $content.style.transition = `height ${this.settings.speed}s`;
                $content.style.height = height;
            }, 0);

            const transitionEndHandler = () => {
                $trigger.setAttribute('aria-selected', 'true');
                $trigger.setAttribute('aria-expanded', 'true');
                $content.classList.add(this.settings.activeClassname);
                $content.setAttribute('aria-hidden', 'false');
                this.toggleFocusElements($accordionElement, 'enable');
                $content.style.height = '';
                $content.removeEventListener('transitionend', transitionEndHandler);
            };

            $content.addEventListener('transitionend', transitionEndHandler);
        }
    }

    closeContent ($accordionElement) {
        const $content = $accordionElement.querySelector('[' + this.settings.initAttr + '="' + this.settings.contentAttr + '"]');
        const $trigger = $accordionElement.querySelector('[' + this.settings.initAttr + '="' + this.settings.triggerAttr + '"]');

        if ($content && $trigger) {
            $accordionElement.classList.remove(this.settings.activeClassname);
            $content.style.height = $content.clientHeight + 'px';

            setTimeout(() => {
                $content.style.transition = `height ${this.settings.speed}s`;
                $content.style.height = '0px';
            }, 0);

            const transitionEndHandler = () => {
                $trigger.setAttribute('aria-selected', 'false');
                $trigger.setAttribute('aria-expanded', 'false');
                $content.classList.remove(this.settings.activeClassname);
                $content.setAttribute('aria-hidden', 'true');
                this.toggleFocusElements($accordionElement, 'disable');
                $content.style.height = '';
                $content.removeEventListener('transitionend', transitionEndHandler);
            };

            $content.addEventListener('transitionend', transitionEndHandler);
        }
    }

    destroy () {
        this.setEvents(false);
    }
}

export { Accordion };
