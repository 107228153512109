import './story-stage.scss';
import Swiper from 'swiper';
import { ResizeHandler } from '../../utilities/js/resize-handler';

class StoryStage {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-storystage',
            sliderSuffix: 'slider',
            slideSuffix: 'slide'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.resizeHandler = new ResizeHandler();
        this.sliderActive = false;
        this.storyStageSlider = null;

        // Elements
        this.$storyStage = element;
        this.$storyStageSlider = this.$storyStage.querySelector(`[${this.settings.initAttr}="${this.settings.sliderSuffix}"]`);
        this.$$storyStageSlides = this.$storyStage.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideSuffix}"]`);
    }

    initialize () {
        this.resizeHandler.init();

        if (this.$$storyStageSlides.length > 0) {
            this.checkSize();
            this.resizeHandler.customFunctions.push(() => {
                this.checkSize();
            });
        }
    }

    checkSize () {
        if (window.innerWidth < 768) {
            if (this.sliderActive === false) {
                this.initSlider();
                this.sliderActive = true;
            }
        } else {
            if (this.sliderActive === true) {
                this.destroySlider();
                this.sliderActive = false;
            }
        }
    }

    initSlider () {
        this.storyStageSlider = new Swiper(this.$storyStageSlider, {
            speed: 500,
            effect: 'fade',
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            draggable: true,
            centeredSlides: true,
            centeredSlidesBounds: true,
            clickable: true,
            initialSlide: 1,
            slideToClickedSlide: true
        });

        this.sliderActive = true;
    }

    destroySlider () {
        if (typeof this.storyStageSlider !== 'undefined' && this.storyStageSlider !== null) {
            this.storyStageSlider.destroy();
        }
    }
}

export { StoryStage };
