import { uniqueID } from './helper';

class YoutubePlayer {
    constructor (element, options) {
        const settings = {
            clones: null,
            showinfo: 0,
            rel: 0,
            color: 'white',
            autoplay: false,
            height: 720,
            width: 1280,
            silent: false,
            fullscreen: false,
            loop: false,
            onCall: null,
            onStart: null,
            onResume: null,
            onEnd: null,
            onPause: null,
            root: null,
            observe: true,
            gallery: false
        };

        this.settings = Object.assign({}, settings, options);
        this.$element = element;
        this.player = {};
        this.builded = false;

        this.touchDevice = !window.browser.csshover;
        this.observer = null;
        this.youtubeId = this.$element.getAttribute('data-youtube');
        this.youtubeIdScreen = this.$element.getAttribute('data-youtube-screen');
        this.activeVideoId = null;
        this.firststart = true;

        this.initialize();
    }

    initialize () {
        this.$element.classList.add('available');

        if (this.settings.observe) {
            const handleIntersect = (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (!this.builded) {
                            if (this.settings.silent && this.settings.autoplay) {
                                this.buildPlayer(this.settings.silent);
                            }
                        } else {
                            if (typeof this.player.video.playVideo !== 'undefined') {
                                this.player.video.playVideo();
                            }
                        }
                    } else {
                        if (this.builded) {
                            if (typeof this.player.video.pauseVideo !== 'undefined') {
                                this.player.video.pauseVideo();
                            }
                        }
                    }
                });
            };

            this.videoObserver = new IntersectionObserver(handleIntersect, {
                threshold: 0
            });
        }

        this.$element.addEventListener('click', () => {
            if (typeof this.settings.onCall === 'function') {
                this.settings.onCall();
            }

            if (this.settings.silent === true && this.player.video) {
                this.player.video.unMute();
                this.player.silent = true;
            } else {
                if (!this.builded) {
                    this.buildPlayer(this.settings.silent);
                }
            }
        });

        if (this.settings.clones !== null && this.settings.clones.length > 0) {
            this.settings.clones.forEach($clone => {
                $clone.addEventListener('click', () => {
                    if (typeof this.settings.onCall === 'function') {
                        this.settings.onCall();
                    }

                    if (this.settings.silent === true && this.player.video) {
                        this.player.video.unMute();
                        this.player.silent = true;
                    } else {
                        if (!this.builded) {
                            this.buildPlayer(this.settings.silent);
                        }
                    }
                });
            });
        }

        if (!this.touchDevice) {
            if (this.settings.observe) {
                if (this.settings.root !== null) {
                    this.videoObserver.observe(this.settings.root);
                } else {
                    this.videoObserver.observe(this.$element);
                }
            }
        }

        if (this.settings.gallery) {
            this.buildPlayer(this.settings.silent);
        }
    }

    buildPlayer (silent) {
        this.activeVideoId = window.innerWidth >= 768 && this.youtubeIdScreen !== null ? this.youtubeIdScreen : this.youtubeId;
        const $oldPlayer = this.$element.querySelectorAll('iframe');
        console.log(this.activeVideoId);

        if ($oldPlayer.length === 1) {
            $oldPlayer.remove();
        }

        let mute = 0;
        if (silent === true) {
            mute = 1;
        }

        const $player = document.createElement('div');

        $player.setAttribute('id', uniqueID());
        $player.className = 'player';

        const $parent = this.$element.parentNode;

        $parent.insertBefore($player, this.$element.nextSibling);

        this.playerId = $player.id;

        this.player.playing = false;
        this.player.video = new window.YT.Player($player.id, {
            height: this.settings.height,
            width: this.settings.width,
            videoId: this.activeVideoId, // eslint-disable-line
            host: 'https://www.youtube-nocookie.com',
            playerVars: {
                showinfo: this.settings.showinfo,
                rel: this.settings.rel,
                color: this.settings.color,
                fs: 1,
                playsinline: 0
            },
            events: {
                onReady: () => {
                    if (mute === 1) {
                        this.player.video.mute();
                    }
                    this.player.video.playVideo();
                },
                onStateChange: (event) => {
                    if (event.data === 1) { // start video
                        if (this.player.playing === false) {
                            this.$element.classList.add('playing');

                            if (typeof window.videoplayer !== 'undefined' && window.videoplayer.playing === true) {
                                window.videoplayer.video.pauseVideo();
                                window.videoplayer.video.playing = false;
                            }
                            this.player.playing = true;
                        }

                        if (this.firststart === true) {
                            this.firststart = false;

                            if (typeof this.settings.onStart === 'function') {
                                this.settings.onStart(this.activeVideoId);
                            }
                        } else {
                            if (typeof this.settings.onResume === 'function') {
                                this.settings.onResume(this.activeVideoId);
                            }
                        }

                        window.videoplayer = this.player;
                    } else if (event.data === 2) { // pause video
                        this.player.playing = false;

                        if (typeof this.settings.onPause === 'function') {
                            const progressPercentage = parseInt(100 / this.player.video.getDuration() * this.player.video.getCurrentTime());
                            this.settings.onPause(this.activeVideoId, progressPercentage);
                        }
                    } else if (event.data === 0) { // video ended
                        this.player.playing = false;
                        this.$element.classList.remove('playing');
                        this.builded = false;

                        if (typeof this.settings.onEnd === 'function') {
                            this.settings.onEnd(this.activeVideoId);
                        }

                        if (this.settings.loop === true) {
                            if (mute === 1) {
                                this.player.video.mute();
                            }
                            this.player.video.playVideo();
                        }
                    }
                }
            }
        });
        this.builded = true;
    }

    play () {
        if (this.player.playing === false) {
            this.player.video.playVideo();
        }
    }

    pause () {
        if (this.player.playing === true) {
            this.player.video.pauseVideo();
        }
    }

    destroy () {
        this.player.playing = false;
        this.$element.classList.remove('available');
        this.$element.classList.remove('playing');
        this.builded = false;

        const $player = document.getElementById(this.playerId);
        if (typeof $player !== 'undefined') {
            $player.remove();
        }
    }
}

export { YoutubePlayer };
