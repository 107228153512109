import './home-stage.scss';
import { ScrollCaptain } from 'scroll-captain';
import { smoothScrollTo } from '../../utilities/js/helper';

class HomeStage {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-homestage',
            fadeInSuffix: 'fade-in',
            stickySuffix: 'sticky',
            speed1_Suffix: 'speed-1',
            speed2_Suffix: 'speed-2',
            speed3_Suffix: 'speed-3'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.eventScroller = window.eventScroller;
        this.animation = null;
        this.videoEnd = false;

        // Elements
        this.$homeStage = element;
        this.$$videos = this.$homeStage.querySelectorAll('video');
    }

    initialize () {
        this.initAnimation();

        if (this.$$videos.length > 0) {
            this.enableAnimationOnVideoEnd(this.$$videos[window.innerWidth < 768 ? 0 : 1]);
        }
    }

    enableAnimationOnVideoEnd (video) {
        video.addEventListener('loadedmetadata', () => {
            const duration = (video.duration) * 1000;

            window.setTimeout(() => {
                if (window.scrollY < this.animation.animationArea) {
                    smoothScrollTo(0, this.animation.animationArea, window.OPTIONS.speed);
                }

                this.eventScroller.customFunctions.push(() => {
                    if (window.scrollY < this.animation.animationArea) {
                        window.scrollTo(0, this.animation.animationArea);
                    }
                });

                this.videoEnd = true;
            }, duration);
        });
    }

    initAnimation () {
        this.animation = new ScrollCaptain(this.$homeStage, {
            initAttr: this.settings.initAttr,
            triggerPosition: 0,
            top: '-10%',
            bottom: '-100%',
            cssSpace: 'sticky',
            onScroll: (progress) => {
                this.$homeStage.style.setProperty('--progress', progress);
            },
            animateWrapper: {
                targetSuffix: this.settings.fadeInSuffix,
                opacity: { 0: 0, 80: 1 },
                onscroll: true
            },
            animateSticky: {
                targetSuffix: this.settings.stickySuffix,
                sticky: true
            },
            animateSpeed1: {
                targetSuffix: this.settings.speed1_Suffix,
                onscroll: true
            },
            animateSpeed2: {
                targetSuffix: this.settings.speed2_Suffix,
                onscroll: true
            },
            animateSpeed3: {
                targetSuffix: this.settings.speed3_Suffix,
                onscroll: true
            },
            breakpoints: {
                768: {
                    animateSpeed1: {
                        translateX: { 0: -50, 50: 0 }
                    },
                    animateSpeed2: {
                        translateX: { 0: -100, 50: 0 }
                    },
                    animateSpeed3: {
                        translateX: { 0: -20, 50: 0 }
                    }
                }
            }
        });
    }
}

export { HomeStage };
