import './video-player.scss';
import { YoutubePlayer } from '../../utilities/js/youtube-player.js';
import { smoothScrollTo } from '../../utilities/js/helper';

class Videoplayer {
    constructor ($element, options) {
        const defaults = {
            videoAttr: 'video',
            cloneAttr: 'clone'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$videoplayer = $element;
        this.$videoplayerFile = this.$videoplayer.querySelector('[data-videoplayer="' + this.settings.videoAttr + '"]');
        this.$$clones = this.$videoplayer.querySelectorAll('[data-videoplayer="' + this.settings.cloneAttr + '"]');

        this.initialize();
    }

    initialize () {
        if (this.$videoplayerFile !== null) {
            this.initYoutubePlayer(YoutubePlayer);
        }
    }

    initYoutubePlayer (YoutubePlayer) {
        const $video = this.$videoplayerFile;

        if ($video !== null) {
            const videoPlayer = new YoutubePlayer($video, {
                eventScroller: window.eventScroller,
                autoplay: true,
                clones: this.$$clones.length > 0 ? this.$$clones : null,
                onCall: () => {
                    this.$videoplayer.classList.add('show--video');
                    smoothScrollTo(0, 0, window.OPTIONS.speed);
                },
                onStart: (videoId) => {
                    console.log(videoId);
                },
                onEnd: (videoId) => {
                    console.log(videoId);
                    if (videoPlayer.player.video.isMuted() === false || videoPlayer.mute === 0) {
                        videoPlayer.destroy();
                        this.$videoplayer.classList.remove('show--video');
                    }
                },
                onPause: (videoId, progress) => {
                    console.log(videoId, progress);
                }
            });
        }
    }
}

export { Videoplayer };
