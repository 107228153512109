import './data-graph.scss';
import { ScrollCaptain } from 'scroll-captain';

class DataGraph {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-datagraph',
            colorAttr: 'data-datagraph-color',
            graphSuffix: 'graph',
            scaleSuffix: 'scale',
            toRightSuffix: 'left-right',
            toLeftSuffix: 'right-left',
            toBottomSuffix: 'top-bottom',
            toTopSuffix: 'bottom-top'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.graphAnimation = null;

        // Elements
        this.$dataGraph = element;
        this.$$listItems = this.$dataGraph.querySelectorAll(`[${this.settings.colorAttr}]`);
        this.$svg = this.$dataGraph.querySelector(`[${this.settings.initAttr}="${this.settings.graphSuffix}"]`);
    }

    initialize () {
        if (this.$$listItems.length > 0) {
            this.setColor();
        }

        if (this.$svg) {
            this.$svg.setAttribute(this.settings.initAttr, 'svg');
            this.initAnimation();
        }
    }

    setColor () {
        this.$$listItems.forEach($listItem => {
            const color = $listItem.getAttribute(this.settings.colorAttr);

            if (color !== '') {
                $listItem.style.backgroundColor = color;

                if (color === '#fff' || color === '#ffffff') {
                    $listItem.style.border = '1px solid #000000';
                } else {
                    $listItem.style.border = `1px solid ${color}`;
                }
            }
        });
    }

    initAnimation () {
        this.graphAnimation = new ScrollCaptain(this.$dataGraph, {
            // Basic options
            initAttr: this.settings.initAttr,
            bottom: '-10%',
            triggerPosition: 0.5,
            animateSVG: {
                targetSuffix: 'fade-in',
                translateY: { 0: 100, 100: 0 },
                opacity: { 0: 0, 100: 1 },
                duration: 0.7,
                easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
                resetOnScrollDown: false
            },
            animateScalePaths: {
                targetSuffix: this.settings.scaleSuffix,
                scale: { 0: 0, 100: 1 },
                duration: 0.7,
                delay: { time: 0.2, multiplier: 0.3 },
                easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
                resetOnScrollDown: false
            },
            animateToRight: {
                targetSuffix: this.settings.toRightSuffix,
                translateX: { 0: -100, 100: 0 },
                duration: 0.7,
                delay: { time: 0.2, multiplier: 0.3 },
                easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
                resetOnScrollDown: false
            },
            animateToLeft: {
                targetSuffix: this.settings.toLeftSuffix,
                translateX: { 0: 100, 100: 0 },
                duration: 0.7,
                delay: { time: 0.2, multiplier: 0.3 },
                easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
                resetOnScrollDown: false
            },
            animateToBottom: {
                targetSuffix: this.settings.toBottomSuffix,
                translateY: { 0: -100, 100: 0 },
                duration: 0.7,
                delay: { time: 0.2, multiplier: 0.3 },
                easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
                resetOnScrollDown: false
            },
            animateToTop: {
                targetSuffix: this.settings.toTopSuffix,
                translateY: { 0: 100, 100: 0 },
                duration: 0.7,
                delay: { time: 0.2, multiplier: 0.3 },
                easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
                resetOnScrollDown: false
            }
        });
    }
}

export { DataGraph };
