import './share-teaser.scss';
import { Tracking } from '../../base/tracking.js';

class ShareTeaser {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-shareteaser',
            openSuffix: 'open',
            pathAttr: 'data-shareteaserref',
            copySuffix: 'copy',
            closeSuffix: 'close',
            referenceSuffix: 'reference'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.isOpen = false;

        // Elements
        this.$shareTeaser = element;
        this.$openButton = this.$shareTeaser.querySelector(`[${this.settings.initAttr}="${this.settings.openSuffix}"]`);
        this.$referencedOverlay = this.$shareTeaser.querySelector(`[${this.settings.initAttr}="${this.settings.referenceSuffix}"]`);
        this.$overlay = null;
        this.$copyButton = null;
    }

    initialize () {
        if (this.$referencedOverlay && this.$openButton) {
            this.setEvents();
        }
    }

    addToClipboard () {
        const currentPageURL = window.location.href;
        navigator.clipboard.writeText(currentPageURL);

        this.$copyButton.classList.add('share-overlay__option--success');

        setTimeout(() => {
            this.$copyButton.classList.remove('share-overlay__option--success');
        }, 1000);
    }

    generateOverlay () {
        if (this.$overlay === null) {
            const clonedElement = this.$referencedOverlay.cloneNode(true);
            this.$referencedOverlay.remove();

            this.$overlay = document.createElement('div');
            this.$overlay.classList.add('share-overlay');
            this.$overlay.appendChild(clonedElement);
            document.body.appendChild(this.$overlay);

            window.setTimeout(() => {
                // Close button
                this.$$closeButtons = document.querySelectorAll(`[${this.settings.initAttr}="${this.settings.closeSuffix}"]`);
                if (this.$$closeButtons.length > 0) {
                    this.$$closeButtons.forEach($closeButton => {
                        $closeButton.addEventListener('click', () => {
                            this.closeOverlay();
                        });
                    });
                }

                // Copy button
                this.$copyButton = document.querySelector(`[${this.settings.initAttr}="${this.settings.copySuffix}"]`);
                if (this.$copyButton) {
                    this.$copyButton.addEventListener('click', (e) => {
                        e.preventDefault();
                        this.addToClipboard();
                    });
                }

                this.$$trackingCtas = this.$overlay.querySelectorAll('[data-tracking]');

                if (this.$$trackingCtas.length > 0) {
                    this.$$trackingCtas.forEach($trackingCta => {
                        const trackingAPI = new Tracking($trackingCta);
                        trackingAPI.initialize();
                    });
                }
            }, 100);
        }

        window.setTimeout(() => {
            document.body.classList.add('body--has-overlay');
            this.$overlay.classList.add('share-overlay--visible');
        }, 100);
    }

    closeOverlay () {
        if (this.isOpen) {
            document.body.classList.remove('body--has-overlay');
            this.$overlay.classList.remove('share-overlay--visible');
            this.isOpen = false;
        }
    }

    openOverlay () {
        if (!this.isOpen) {
            this.generateOverlay();
            this.isOpen = true;
        }
    }

    addTracking () {
        const $trackingCta = this.$overlay.querySelector('[data-tracking]');

        if ($trackingCta) {
            const trackingAPI = new Tracking($trackingCta);
            trackingAPI.initialize();
        }
    }

    setEvents () {
        this.$openButton.addEventListener('click', () => {
            this.openOverlay();
        });
    }
}

export { ShareTeaser };
