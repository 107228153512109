import './pillars.scss';
import { ScrollCaptain } from 'scroll-captain';
import Swiper, { Pagination } from 'swiper';

class Pillars {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-pillars',
            glassSuffix: 'glass',
            fullWaterSuffix: 'full-water',
            halffullWaterSuffix: 'halffull-water',
            almostEmptyWaterSuffix: 'almostempty-water',
            contentSuffix: 'content',
            sliderSuffix: 'slider',
            overlaySuffix: 'overlay'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.pillarsAnimation = null;
        this.resizeHandler = window.resizeHandler;
        this.pillarsSliderActive = false;
        this.pillarsSlider = null;

        // Elements
        this.$pillars = element;
        this.$$glassGraphics = this.$pillars.querySelectorAll(`[${this.settings.initAttr}^="${this.settings.glassSuffix}-"]`);
        this.$pillarsSlider = this.$pillars.querySelector(`[${this.settings.initAttr}="${this.settings.sliderSuffix}"]`);
        this.$$overlays = this.$pillars.querySelectorAll(`[${this.settings.initAttr}="${this.settings.overlaySuffix}"]`);
    }

    initialize () {
        this.initAnimation();

        if (this.$$glassGraphics.length > 0 && this.$$overlays.length > 0) {
            this.checkSize();
            this.resizeHandler.customFunctions.push(() => {
                this.checkSize();
            });
            this.setEvents();
        }
    }

    checkSize () {
        if (window.innerWidth < 1024) {
            if (this.pillarsSliderActive === false) {
                this.initSlider();
                this.pillarsSliderActive = true;
            }
        } else {
            if (this.pillarsSliderActive === true) {
                this.destroySlider();
                this.pillarsSliderActive = false;
            }
        }
    }

    initSlider () {
        this.pillarsSlider = new Swiper(this.$pillarsSlider, {
            modules: [Pagination],
            speed: 500,
            effect: 'fade',
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            draggable: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        });

        this.pillarsSliderActive = true;
    }

    destroySlider () {
        if (typeof this.pillarsSlider !== 'undefined' && this.pillarsSlider !== null) {
            this.pillarsSlider.destroy();
        }
    }

    initAnimation () {
        const glassAnimations = {};
        const glassAnimationsDesktop = {};

        this.$$glassGraphics.forEach(($glass, index) => {
            glassAnimations[`animateGlass${index + 1}`] = {
                ...(glassAnimations[`animateCircle${index + 1}`] || {}),
                targetSuffix: `glass-${index + 1}`,
                translateX: { 0: 50 * (index + 1) * 2, 100: null },
                improvePerformance: false,
                duration: 0.8,
                resetOnScrollDown: false
            };

            glassAnimationsDesktop[`animateGlass${index + 1}`] = {
                ...(glassAnimationsDesktop[`animateCircle${index + 1}`] || {}),
                duration: 0,
                onscroll: true,
                improvePerformance: false
            };
        });

        this.pillarsAnimation = new ScrollCaptain(this.$pillars, {
            // Basic options
            initAttr: this.settings.initAttr,
            triggerSuffix: 'trigger',
            bottom: '-40%',
            ...glassAnimations,
            animateFullGlassWater: {
                targetSuffix: this.settings.fullWaterSuffix,
                scaleY: { 60: 1, 100: 0.05 },
                translateX: -30,
                duration: 1,
                resetOnScrollDown: false
            },
            animateHalfFullGlassWater: {
                targetSuffix: this.settings.halffullWaterSuffix,
                scaleY: { 60: 0.75, 100: 0.05 },
                translateX: { 60: -40, 80: 40, 100: null },
                duration: 1,
                delay: 0.4,
                resetOnScrollDown: false
            },
            animateAlmostEmptyGlassWater: {
                targetSuffix: this.settings.almostEmptyWaterSuffix,
                scaleY: { 60: 0.4, 100: 0.05 },
                translateX: { 60: 10, 80: -20, 100: null },
                duration: 1,
                delay: 0.8,
                resetOnScrollDown: false
            },
            animateItemContent: {
                targetSuffix: this.settings.contentSuffix,
                translateY: { 0: 50, 100: null },
                opacity: { 0: 0, 100: null },
                duration: 0.4,
                delay: { time: 1.5, multiplier: 0.2 },
                easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
                resetOnScrollDown: false,
                resetOnScrollUp: false
            },
            breakpoints: {
                768: {
                    top: '80%',
                    bottom: '0%',
                    animateFullGlassWater: {
                        onscroll: true,
                        duration: 0
                    },
                    animateHalfFullGlassWater: {
                        onscroll: true,
                        duration: 0
                    },
                    animateAlmostEmptyGlassWater: {
                        onscroll: true,
                        duration: 0
                    },
                    ...glassAnimationsDesktop
                }
            }
        });
    }

    setEvents () {
        this.$$glassGraphics.forEach(($glassGraphic, index) => {
            $glassGraphic.addEventListener('click', () => {
                if (this.pillarsSliderActive) {
                    this.pillarsSlider.slideTo(index);
                }
            });

            $glassGraphic.addEventListener('mouseover', () => {
                if (!this.pillarsSliderActive) {
                    this.$$overlays[index].classList.add('pillars__description--active');
                }
            });

            $glassGraphic.addEventListener('mouseleave', () => {
                if (!this.pillarsSliderActive) {
                    this.$$overlays[index].classList.remove('pillars__description--active');
                }
            });
        });
    }
}

export { Pillars };
