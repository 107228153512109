import './map.scss';
import Swiper, { Navigation, Scrollbar } from 'swiper';
import { Tracking } from '../../base/tracking.js';
class Map {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-map',
            latAttr: 'data-map-lat',
            lngAttr: 'data-map-lng',
            categoryAttr: 'data-map-category',
            wrapperSuffix: 'wrapper',
            sliderSuffix: 'slider',
            slideSuffix: 'slide',
            controlsSuffix: 'controls',
            graphicSuffix: 'graphic-container',
            overlayWrapperSuffix: 'overlay-wrapper',
            markerSuffix: 'marker',
            introSuffix: 'intro',
            headlineSuffix: 'headline',
            buttonSuffix: 'button'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.resizeHandler = window.resizeHandler;

        // Elements
        this.$map = element;
        this.$mapWrapper = this.$map.querySelector(`[${this.settings.initAttr}="${this.settings.wrapperSuffix}"]`);
        this.$$slides = this.$map.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideSuffix}"]`);
        this.$mapSlider = this.$map.querySelector(`[${this.settings.initAttr}="${this.settings.sliderSuffix}"]`);
        this.$mapControls = this.$map.querySelector(`[${this.settings.initAttr}="${this.settings.controlsSuffix}"]`);
        this.$$markers = [];
        this.$graphicContainer = this.$map.querySelector(`[${this.settings.initAttr}="${this.settings.graphicSuffix}"]`);
        this.$overlayWrapper = this.$map.querySelector(`[${this.settings.initAttr}="${this.settings.overlayWrapperSuffix}"]`);
        this.$overlay = null;
        this.$overlayContent = null;

        // Options
        this.markerPositions = {};
        this.activeIndex = -1;
        this.isForcedScrolling = false;
    }

    initialize () {
        if (this.$$slides.length > 0 && this.$mapWrapper && this.$graphicContainer) {
            this.setOffset();
            this.generateMarker();
            this.positionMarker();

            if (this.$mapSlider && this.$mapControls) {
                this.initSlider();
            }

            this.resizeHandler.customFunctions.push(() => {
                if (this.activeIndex > -1) {
                    this.toggleActiveStates(-1);
                }

                this.positionMarker();

                setTimeout(() => {
                    this.setOffset();
                    this.setMinHeight();
                    this.slider.update();
                }, 500);
            });

            this.initDragToScroll();

            // Center map on init
            this.setScrollPosition();

            this.setEvents();
        }
    }

    initDragToScroll () {
        let isDragging = false;
        let startPosX = 0;
        let startPosY = 0; // For vertical scrolling
        let scrollLeft = 0;
        let scrollTop = 0; // For vertical scrolling

        const startDragging = (e) => {
            isDragging = true;
            startPosX = e.pageX - this.$graphicContainer.offsetLeft;
            startPosY = e.pageY - this.$graphicContainer.offsetTop; // For vertical scrolling
            scrollLeft = this.$graphicContainer.scrollLeft;
            scrollTop = this.$graphicContainer.scrollTop; // For vertical scrolling
            // Optional: Prevent text selection during dragging
            e.preventDefault();
        };

        const stopDragging = () => {
            if (!isDragging) return;
            isDragging = false;
        };

        const doScroll = (e) => {
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - this.$graphicContainer.offsetLeft;
            const y = e.pageY - this.$graphicContainer.offsetTop; // For vertical scrolling
            const walkX = (x - startPosX) * 2; // Adjustable scroll speed
            const walkY = (y - startPosY) * 2; // Adjustable scroll speed for vertical scrolling
            this.$graphicContainer.scrollLeft = scrollLeft - walkX;
            this.$graphicContainer.scrollTop = scrollTop - walkY; // For vertical scrolling
        };

        // Event listeners for dragging start and end
        this.$graphicContainer.addEventListener('mousedown', startDragging);
        this.$graphicContainer.addEventListener('mouseleave', stopDragging);
        this.$graphicContainer.addEventListener('mouseup', stopDragging);
        this.$graphicContainer.addEventListener('mousemove', doScroll);
    }

    setMinHeight () {
        let minHeight = 0;
        const $image = this.$map.querySelector('figure');

        this.$$slides.forEach(($slide) => {
            let activeSlideHeight = 0;
            let defaultSlideHeight = 0;
            let slideMinHeight = 0;
            const $headline = $slide.querySelector(`[${this.settings.initAttr}="${this.settings.headlineSuffix}"]`);
            const $intro = $slide.querySelector(`[${this.settings.initAttr}="${this.settings.introSuffix}"]`);
            const $button = $slide.querySelector(`[${this.settings.initAttr}="${this.settings.buttonSuffix}"]`);

            if ($image) {
                defaultSlideHeight += $image.clientHeight;
            }

            if ($headline) {
                defaultSlideHeight += $headline.clientHeight;
            }

            if ($intro) {
                activeSlideHeight += $intro.clientHeight;
            }

            if ($button) {
                activeSlideHeight += $button.clientHeight;
            }

            if (window.innerWidth < 768) {
                if ($headline) {
                    activeSlideHeight += $headline.clientHeight;
                }
            }

            slideMinHeight = defaultSlideHeight;

            if (activeSlideHeight > defaultSlideHeight) {
                slideMinHeight = activeSlideHeight;
            }

            if (slideMinHeight > minHeight) {
                minHeight = slideMinHeight;
            }
        });

        this.$mapSlider.style.setProperty('--min-height', `${minHeight}px`);
    }

    initSlider () {
        this.slider = new Swiper(this.$mapSlider, {
            modules: [Navigation, Scrollbar],
            speed: 500,
            effect: 'fade',
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            centeredSlides: true,
            slideToClickedSlide: true,
            initialSlide: -1,
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            on: {
                init: () => {
                    this.setMinHeight();
                },
                click: (swiper) => {
                    if (swiper.clickedIndex !== this.activeIndex) {
                        this.toggleActiveStates(swiper.clickedIndex);
                        this.slider.slideTo(this.activeIndex);
                    }

                    this.setScrollPosition();
                },
                sliderMove: () => {
                    if (this.activeIndex !== -1) {
                        this.toggleActiveStates(-1);
                    }
                }
            },
            breakpoints: {
                768: {
                    centeredSlidesBounds: true
                }
            }
        });
    }

    setOffset () {
        const $image = this.$map.querySelector('figure');

        if ($image) {
            for (let index = 0; index < this.$$slides.length; index++) {
                const $headline = this.$$slides[index].querySelector(`[${this.settings.initAttr}="${this.settings.headlineSuffix}"]`);
                const style = window.getComputedStyle($headline);
                const headlineHeight = $headline.clientHeight;
                const paddingTop = style.getPropertyValue('padding-top');
                const paddingTopValue = parseInt(paddingTop, 10);

                if (window.innerWidth >= 768) {
                    this.$$slides[index].style.setProperty('--offset', $image.clientHeight + headlineHeight - paddingTopValue);
                } else {
                    this.$$slides[index].style.setProperty('--offset', $image.clientHeight);
                }
            }
        }
    }

    toggleActiveStates (index) {
        this.activeIndex = index;

        // Enable/disable active marker
        this.$$markers.forEach(($marker, index) => {
            if (index === this.activeIndex) {
                $marker.classList.add('map__marker--active');
            } else {
                $marker.classList.remove('map__marker--active');
            }
        });

        // Enable/disable active slide
        this.$$slides.forEach(($slide, index) => {
            if (index === this.activeIndex) {
                $slide.classList.add('map__item--active');
            } else {
                $slide.classList.remove('map__item--active');
            }
        });

        // Generate/replace or delete overlay
        if (this.$overlayWrapper) {
            if (this.activeIndex === -1 && this.$overlay !== null) {
                this.deleteOverlay();
            }

            if (this.$overlay !== null && this.activeIndex > -1) {
                this.replaceOverlay();
            }

            if (this.$overlay === null && this.activeIndex > -1) {
                this.generateOverlay();
            }
        }
    }

    replaceOverlay () {
        const clone = this.generateOverlayContent();
        this.$overlayContent.innerHTML = clone.innerHTML;
        this.addTracking();
    }

    deleteOverlay () {
        this.$map.classList.remove('map--has-overlay');
        this.$overlay.classList.remove('map__overlay--active');

        setTimeout(() => {
            if (this.$overlay) {
                this.$overlay.remove();
                this.$overlay = null;
                this.$overlayContent = null;
            }
        }, 350);
    }

    generateOverlayContent () {
        this.$map.classList.add('map--has-overlay');
        // Retrieve the active slide element
        const activeSlide = this.$$slides[this.activeIndex];

        // Create the main container for the overlay content
        const overlayContent = document.createElement('div');
        overlayContent.className = 'map__overlay-content';

        // Create a container for both author and location
        const authorLocationContainer = document.createElement('div');
        authorLocationContainer.className = 'map__overlay-author-location';

        // Function to create and append text elements (author, location), including a flag span for location
        const createAndAppendTextElement = (className, text, parentElement, flagCode) => {
            if (text) {
                const element = document.createElement('p');
                element.className = className;

                // If a flag code is provided and the element is for location, prepend a flag icon
                if (flagCode && className.includes('location')) {
                    const flagSpan = document.createElement('span');
                    flagSpan.className = `map-teaser__flag fi fi-${flagCode}`;
                    element.appendChild(flagSpan);

                    // Optional: add a space or any separator after the flag icon
                    const separator = document.createTextNode(' ');
                    element.appendChild(separator);
                }

                const textNode = document.createTextNode(text);
                element.appendChild(textNode);
                parentElement.appendChild(element);
            }
        };

        // Extract flag code from the active slide
        const flagCode = activeSlide.getAttribute('data-map-flag');

        // Create and append author and location to their container
        createAndAppendTextElement('map__overlay-author', activeSlide.getAttribute('data-map-author'), authorLocationContainer);
        createAndAppendTextElement('map__overlay-location', activeSlide.getAttribute('data-map-location'), authorLocationContainer, flagCode);

        // Append the author and location container to the overlay content
        overlayContent.appendChild(authorLocationContainer);

        // Create and append the headline
        createAndAppendTextElement('map__overlay-headline', activeSlide.getAttribute('data-map-headline'), overlayContent);

        // Create and append the image within a figure and span
        const imageSrc = activeSlide.getAttribute('data-map-image');
        if (imageSrc) {
            const figure = document.createElement('figure');
            figure.className = 'map__overlay-image image image--fix';

            const span = this.$$slides[this.activeIndex].querySelector('figure span');

            const clonedSpan = span.cloneNode(true);

            figure.appendChild(clonedSpan);
            overlayContent.appendChild(figure);
        }

        // Create and append the button with the URL
        const buttonUrl = activeSlide.getAttribute('data-map-url');
        if (buttonUrl) {
            const button = document.createElement('a'); // Using an <a> element to act as a button
            button.href = buttonUrl;
            button.className = 'map__overlay-button';
            button.textContent = 'Learn More'; // Customize the button text as needed

            // Extract tracking data
            const trackingData = activeSlide.getAttribute('data-map-tracking');

            overlayContent.appendChild(button);

            if (trackingData !== '') {
                button.setAttribute('data-tracking', trackingData);
            }
        }

        // Return the completed overlay content
        return overlayContent;
    }

    generateOverlay () {
        // Create the outer overlay element
        this.$overlay = document.createElement('div');
        this.$overlay.className = 'map__overlay'; // Initially without 'active' class
        this.$overlay.setAttribute('data-map', 'overlay');

        // Create the inner overlay element
        this.$overlayContent = document.createElement('div');
        this.$overlayContent.className = 'map__overlay-inner';
        this.$overlayContent.setAttribute('data-map', 'overlay-content');
        const clone = this.generateOverlayContent();

        // Append the inner element to the outer one
        this.$overlayContent.innerHTML = clone.innerHTML;
        this.$overlay.appendChild(this.$overlayContent);

        // Add the overlay to the body of the page
        this.$overlayWrapper.appendChild(this.$overlay);

        // Assign the 'active' class after 500ms
        setTimeout(() => {
            if (this.$overlay) {
                this.$overlay.classList.add('map__overlay--active');
                this.addTracking();
            }
        }, 500);
    }

    addTracking () {
        const $trackingCta = this.$overlay.querySelector('[data-tracking]');

        if ($trackingCta) {
            const trackingAPI = new Tracking($trackingCta);
            trackingAPI.initialize();
        }
    }

    positionMarker () {
        const mapWidth = this.$mapWrapper?.offsetWidth;
        const mapHeight = this.$mapWrapper?.offsetHeight;

        this.$$slides.forEach(($slide, index) => {
            // Convert geo-coordinates to x, y positions
            const x = (this.markerPositions[index].lng + 180) * (mapWidth / 360);
            const y = -(this.markerPositions[index].lat - 90) * (mapHeight / 180);

            this.$$markers[index].style.left = `${x}px`;
            this.$$markers[index].style.top = `${y}px`;
        });
    }

    generateMarker () {
        this.$$slides.forEach(($slide, index) => {
            const lat = parseFloat($slide.getAttribute(this.settings.latAttr));
            const lng = parseFloat($slide.getAttribute(this.settings.lngAttr));
            const category = $slide.getAttribute(this.settings.categoryAttr);

            this.markerPositions[index] = {};
            this.markerPositions[index].lat = lat;
            this.markerPositions[index].lng = lng;

            // Create marker
            const $marker = document.createElement('div');
            $marker.className = `map__marker icon-${category}`;
            $marker.setAttribute(this.settings.initAttr, this.settings.markerSuffix);
            this.$mapWrapper.appendChild($marker);

            // Add marker to map wrapper
            this.$$markers.push($marker);
        });
    }

    setScrollPosition () {
        let scrollX;
        let scrollY;

        // Getting the dimensions of the container
        const containerWidth = this.$graphicContainer.offsetWidth;
        const contentWidth = this.$graphicContainer.scrollWidth;
        const containerHeight = this.$graphicContainer.offsetHeight;
        const contentHeight = this.$graphicContainer.scrollHeight;

        if (this.activeIndex === -1) {
            // Centering the content if no specific marker is selected
            scrollX = (contentWidth - containerWidth) / 2;
            scrollY = (contentHeight - containerHeight) / 2;
        } else {
            // Calculations for a specific marker
            const marker = this.$$markers[this.activeIndex];
            const markerRect = marker.getBoundingClientRect();
            const containerRect = this.$graphicContainer.getBoundingClientRect();

            // Calculating the relative position of the marker to the container
            const markerCenterX = markerRect.left + (markerRect.width / 2);
            const containerCenterX = containerRect.left + (containerWidth / 2);

            // Calculating the difference between the center of the marker and the center of the container
            // and adjusting the scroll position according to this difference
            scrollX = this.$graphicContainer.scrollLeft + (markerCenterX - containerCenterX);

            // For vertical centering, if needed
            const markerCenterY = markerRect.top + (markerRect.height / 2);
            const containerCenterY = containerRect.top + (containerHeight / 2);
            scrollY = this.$graphicContainer.scrollTop + (markerCenterY - containerCenterY);
        }

        this.isForcedScrolling = true;

        // Performing the scroll to the calculated position
        this.$graphicContainer.scrollTo({
            top: scrollY,
            left: scrollX,
            behavior: 'smooth'
        });

        // Setup a function to detect scroll end
        let scrollEndTimer = null;
        const onScrollEnd = () => {
            clearTimeout(scrollEndTimer);
            scrollEndTimer = setTimeout(() => {
                this.isForcedScrolling = false;
                this.$graphicContainer.removeEventListener('scroll', onScrollEnd);
            }, 250);
        };

        this.$graphicContainer.addEventListener('scroll', onScrollEnd);
    }

    debounce (func, wait, immediate) {
        let timeout;
        return function () {
            const context = this; const args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    setEvents () {
        // Reset active index and toggle active states if the map is clicked outside a slide or marker.
        this.$map.addEventListener('click', (e) => {
            const isElementClickable = (target, excludedElements) => {
                for (const element of excludedElements) {
                    if (element.contains(target)) {
                        return true;
                    }
                }
                return false;
            };

            if (!isElementClickable(e.target, this.$$slides) && e.target.dataset.map !== this.settings.markerSuffix) {
                this.toggleActiveStates(-1);
            }
        });

        // Set active index by click on marker
        this.$$markers.forEach(($marker, index) => {
            $marker.addEventListener('click', () => {
                if (index !== this.activeIndex) {
                    this.toggleActiveStates(index);
                }

                this.slider.slideTo(this.activeIndex);
                this.setScrollPosition();
            });
        });

        // Close overlay if user scrolls
        this.$graphicContainer.addEventListener('scroll', this.debounce(() => {
            if (this.activeIndex > -1 && !this.isForcedScrolling) {
                this.toggleActiveStates(-1);
            }
        }, 50));
    }
}

export { Map };
