const createElementFromHTML = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    if (div.children.length > 1) {
        const itemsArray = [];
        Array.prototype.push.apply(itemsArray, div.children);

        return itemsArray;
    } else {
        return div.firstChild;
    }
};

const getEntries = (obj) => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    return resArray;
};

const getFormData = ($formGroup) => {
    const formData = new FormData();
    const $singleInputs = $formGroup.querySelectorAll('input:not([type="checkbox"]):not([type="radio"]), select:not([multiple]), textarea');
    const $multipleInputs = $formGroup.querySelectorAll('input[type="checkbox"], select[multiple]');
    const $radioInputs = $formGroup.querySelectorAll('input[type="radio"]:checked');

    for (let s = 0; s < $singleInputs.length; s++) {
        const $field = $singleInputs[s];
        const fieldName = $field.getAttribute('name');
        const fieldValue = $field.value;
        let ignoreField = false;

        if (typeof $field.getAttribute('formdata') !== 'undefined' && $field.getAttribute('formdata') === 'ignore') {
            ignoreField = true;
        }

        if (fieldValue !== '' && ignoreField === false) {
            formData.append(fieldName, fieldValue);
        }
    }

    for (let m = 0; m < $multipleInputs.length; m++) {
        const $field = $multipleInputs[m];
        const fieldName = $field.getAttribute('name');
        let ignoreField = false;

        if (typeof $field.getAttribute('formdata') !== 'undefined' && $field.getAttribute('formdata') === 'ignore') {
            ignoreField = true;
        }

        if (ignoreField === false) {
            if ($field.getAttribute('multiple')) {
                const values = Array.from($field.querySelectorAll('option:checked'), e => e.value);
                for (let v = 0; v < values.length; v++) {
                    formData.append(fieldName, values[v]);
                }
            } else {
                if ($field.checked === true && $field.value !== '') {
                    const fieldValue = $field.value;
                    formData.append(fieldName, fieldValue);
                }
            }
        }
    }

    for (let r = 0; r < $radioInputs.length; r++) {
        const $field = $radioInputs[r];
        const fieldName = $field.getAttribute('name');
        let ignoreField = false;

        if (typeof $field.getAttribute('formdata') !== 'undefined' && $field.getAttribute('formdata') === 'ignore') {
            ignoreField = true;
        }

        if (ignoreField === false) {
            const fieldValue = $field.value;
            formData.append(fieldName, fieldValue);
        }
    }

    return formData;
};

const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
        bottom: rect.top + scrollTop + rect.height
    };
};

const loadImage = (img, loadHandler, errorHandler) => {
    const helperImg = new Image();
    if (loadHandler) {
        helperImg.addEventListener('load', () => {
            loadHandler(img);
        }, false);
    }
    if (errorHandler) {
        helperImg.addEventListener('error', () => {
            errorHandler(img, false);
        }, false);

        helperImg.addEventListener('abort', () => {
            errorHandler(img, true);
        }, false);
    }
    helperImg.src = img.src;
};

const smoothScrollTo = (endX, endY, duration = 400, callback) => {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    // Easing function
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = window.setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);

        if (time >= duration) {
            window.clearInterval(timer);
            if (typeof callback === 'function') {
                callback();
            }
        }
        window.scrollTo(newX, newY);
    }, 1000 / 60); // 60 fps
};

const containerSmoothScrollTo = ($container, endX, endY, duration) => {
    const startX = $container.scrollLeft;
    const startY = $container.scrollTop;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;

    // Easing function
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = window.setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);

        if (time >= duration) {
            window.clearInterval(timer);
        }
        $container.scrollLeft = newX;
        $container.scrollTop = newY;
    }, 1000 / 60); // 60 fps
};

const tryParseJSON = (jsonString) => {
    try {
        const o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object",
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) {
        console.log(e);
    }

    return false;
};

const uniqueID = () => {
    const chr4 = () => {
        return Math.random().toString(16).slice(-4);
    };

    return chr4() + chr4() +
    '-' + chr4() +
    '-' + chr4() +
    '-' + chr4() +
    '-' + chr4() + chr4() + chr4();
};

const preloadVideo = (videoElement) => {
    videoElement.play();

    const b = setInterval(() => {
        if (videoElement.readyState >= 4) {
            videoElement.pause();
            videoElement.currentTime = 0;

            videoElement.dispatchEvent(new CustomEvent('video-ready', {
                detail: {
                    duration: videoElement.duration
                }
            }));

            clearInterval(b);
        }
    }, 500);
};

const loadYoutubeApi = () => {
    if (typeof YT === 'undefined') {
        const tag = document.createElement('script');
        tag.src = '//www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
};

const dispatchForCode = (event, callback) => {
    let code;

    if (event.key !== undefined) {
        code = event.key;
    } else if (event.keyIdentifier !== undefined) {
        code = event.keyIdentifier;
    } else if (event.keyCode !== undefined) {
        code = event.keyCode;
    }

    if (typeof callback === 'function') {
        callback(code);
    }
};

const loadScript = (source, beforeEl, async = true, defer = true) => {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        const prior = beforeEl || document.getElementsByTagName('script')[0];

        script.async = async;
        script.defer = defer;

        function onloadHander (_, isAbort) {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = resolve(script);
                script.onreadystatechange = null;
                script = undefined;

                if (isAbort) {
                    const error = new Error('something went wrong');
                    reject(error);
                } else {
                    resolve(script);
                }
            }
        }

        script.onload = onloadHander;
        script.onreadystatechange = onloadHander;

        script.src = source;
        prior.parentNode.insertBefore(script, prior);
    });
};

// *****************************************************
// Used to inject css-files into head
// Use loadStyle(url) to load a single css file
// Use loadFiles('css', [array of urls]) to load multiple css files
// Use loadStyle().then(() => {}); Used Promise, so you can follow on finish
// *****************************************************
const loadStyle = (src) => {
    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.href = src;
        link.rel = 'stylesheet';

        link.onload = () => resolve(link);
        link.onerror = () => reject(new Error(`Style load error for ${src}`));

        document.head.append(link);
    });
};

// *****************************************************
// Source: css- or js-path of files (array)
// type: 'css' or 'js'
// Use loadFile('string', [array of urls]) to load multiple files
// Use loadFiles().then(() => {}); Used Promise, so you can follow on finish
// *****************************************************
const loadFiles = (dataType, sources) => {
    const promises = [];
    sources.forEach((src) => {
        if (dataType === 'css') {
            promises.push(loadStyle(src));
        } else if (dataType === 'js') {
            promises.push(loadScript(src, document.getElementsByTagName('script')[0]));
        }
    });
    return Promise.all(promises);
};

// ****************************************************************************
// content: content for querySelector to find data-css and data-js attributes
// Place data-css="url to css" (comma seperated if multiple) and data-js="url to js" (comma seperated if multiple) -> on sections (or somewhere else) only if app.request.xmlHttpRequest
// Use checkAndLoadFiles(DOM Element)
// Checked for css and js Files to be loaded. (Used loadFiles, loadStyles and loadScript)
// Use checkAndLoadFiles(content).then((data) => { // fire customEvent on targetContent }); Used Promise, so you can follow on finish
// The returned "data" contains the loaded URLS
// ***************************************************************************
const checkAndLoadFiles = (content) => {
    const promises = [];
    const $dataMap = new Map();
    $dataMap.set('css', content.querySelectorAll('[data-css]'));
    $dataMap.set('js', content.querySelectorAll('[data-js]'));

    for (const data of $dataMap) {
        const URLArray = [];

        if (data[1].length > 0) {
            data[1].forEach((item) => {
                const urlData = item.getAttribute(`data-${data[0]}`);

                if (urlData.indexOf(',') === -1) {
                    URLArray.push(urlData);
                } else {
                    const urls = urlData.split(',');
                    urls.forEach((urlString) => {
                        URLArray.push(urlString.trim());
                    });
                }
            });

            const uniqueSet = new Set(URLArray);
            const filteredUrls = [...uniqueSet];

            promises.push(loadFiles(data[0], filteredUrls));
        }
    }

    return Promise.all(promises);
};
// *****************************************************

export {
    checkAndLoadFiles,
    containerSmoothScrollTo,
    createElementFromHTML,
    dispatchForCode,
    getEntries,
    getFormData,
    getOffset,
    loadYoutubeApi,
    loadStyle,
    loadFiles,
    loadImage,
    loadScript,
    preloadVideo,
    smoothScrollTo,
    tryParseJSON,
    uniqueID
};
