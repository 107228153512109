import './circle-animation.scss';
import { ScrollCaptain } from 'scroll-captain';

class CircleAnimation {
    constructor (element, options) {
        // Default options
        const defaults = {
            initAttr: 'data-circleanimation',
            targetSuffix: 'circle',
            speedAttr: 'data-circleanimation-speed',
            colorAttr: 'data-circleanimation-color'
        };

        // Settings
        this.settings = Object.assign({}, defaults, options);
        this.circleAnimation = null;
        this.translateY = 50;

        // Elements
        this.$circleAnimation = element;
        this.$$circles = this.$circleAnimation.querySelectorAll(`[${this.settings.initAttr}^="${this.settings.targetSuffix}-"]`);

        // Options
        this.colorAnimation = this.$circleAnimation.hasAttribute(this.settings.colorAttr);
    }

    initialize () {
        if (this.$$circles.length > 0) {
            this.initAnimation();
        }
    }

    initAnimation () {
        const circleOptions = {};

        this.$$circles.forEach(($circle, index) => {
            // Initial basic setup for circle animation
            circleOptions[`animateCircle${index + 1}`] = {
                ...(circleOptions[`animateCircle${index + 1}`] || {}),
                targetSuffix: `circle-${index + 1}`,
                onscroll: true
            };

            // Add background color options, if color animation is activated
            if (this.colorAnimation) {
                const nextCircleIndex = index === this.$$circles.length - 1 ? 0 : index + 1;
                const nextCircleStyles = window.getComputedStyle(this.$$circles[nextCircleIndex]);
                const nextCircleBgColor = String(nextCircleStyles.backgroundColor);

                const prevCircleIndex = index === 0 ? this.$$circles.length - 1 : index - 1;
                const prevCircleStyles = window.getComputedStyle(this.$$circles[prevCircleIndex]);
                const prevCircleBgColor = String(prevCircleStyles.backgroundColor);

                const currentCircleStyles = window.getComputedStyle(this.$$circles[index]);
                const currentCircleBgColor = String(currentCircleStyles.backgroundColor);

                circleOptions[`animateCircle${index + 1}`] = {
                    ...circleOptions[`animateCircle${index + 1}`],
                    backgroundColor: { 0: prevCircleBgColor, 50: currentCircleBgColor, 100: nextCircleBgColor }
                };
            }

            // Add transformation
            let speedMultiplier = $circle.getAttribute(this.settings.speedAttr);
            speedMultiplier = speedMultiplier !== null ? Number(speedMultiplier) : false;

            if (speedMultiplier > 0) {
                circleOptions[`animateCircle${index + 1}`].translateY = {
                    ...circleOptions[`animateCircle${index + 1}`].translateY,
                    0: this.translateY * speedMultiplier,
                    100: this.translateY * speedMultiplier * -1
                };
            }
        });

        this.circleAnimation = new ScrollCaptain(this.$circleAnimation, {
            // Basic options
            initAttr: this.settings.initAttr,
            ...circleOptions
        });
    }
}

export { CircleAnimation };
