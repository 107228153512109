import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { getOffset, smoothScrollTo } from './utilities/js/helper';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';
import { MediaMatchHandler } from './utilities/js/media-match-handler';

// Base
import { Tracking } from './base/tracking';

// Components
import { Accordion } from './components/accordion/accordion';
import { Videoplayer } from './components/video-player/video-player';
import { StoryStage } from './components/story-stage/story-stage';
import { HomeStage } from './components/home-stage/home-stage';

// Global Animations
import { CircleAnimation } from './animations/circle-animation/circle-animation';

// Areas
import { DataGraph } from './areas/data-graph/data-graph';
import { Map } from './areas/map/map';
import { Pillars } from './areas/pillars/pillars';
import { ShareTeaser } from './areas/share-teaser/share-teaser';
import { TextImageSlider } from './areas/text-image-slider/text-image-slider';

// GLOBAL OPTIONS
window.OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    speed: 800
};

window.eventScroller = new EventScroller();
window.resizeHandler = new ResizeHandler();
window.mediaMatch = new MediaMatchHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;

const browserCheck = () => {
    window.browser = {
        csshover: !window.matchMedia('(pointer:coarse)').matches,
        ios: (/iPhone|iPad|iPod/i).test(navigator.userAgent),
        iphone: (/iPhone|iPod/i).test(navigator.userAgent),
        android: (/(android)/i).test(navigator.userAgent),
        firefox: (/firefox/i).test(navigator.userAgent),
        safari: (/Version\/[\d.]+.*Safari/).test(navigator.userAgent)
    };

    Object.entries(window.browser).forEach(([key, value]) => {
        document.documentElement.classList.add((!value) ? `no-${key}` : `${key}`);
    });
};
const checkInview = () => {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('inview');
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.1 });

    const $inviews = document.querySelectorAll('[data-inview]:not(.inview)');
    $inviews.forEach((target) => {
        observer.observe(target);
    });
};

const initContent = ($content) => {
    console.log('start app');

    const $$anchor = $content.querySelectorAll('a[href*="#"]');

    if ($$anchor.length > 0) {
        $$anchor.forEach($anchor => {
            $anchor.addEventListener('click', function (e) {
                const url = new URL(this.href);
                const isSamePage = url.origin + url.pathname === window.location.origin + window.location.pathname;

                if (isSamePage && url.hash) {
                    e.preventDefault();
                    const $target = document.querySelector(url.hash);

                    if ($target) {
                        const anchorDimensions = getOffset($target);
                        const anchorDimensionsTop = anchorDimensions.top;
                        smoothScrollTo(0, anchorDimensionsTop, window.OPTIONS.speed);

                        window.history.pushState({}, '', url);
                    }
                } else if (!isSamePage) {
                    window.location = this.href;
                }
            });
        });
    }

    loadLazyImages();
};

domready(function () {
    const initApplication = () => {
        browserCheck();

        initContent(document);

        window.eventScroller.init();
        window.resizeHandler.init();

        // Tracking
        const $$tracking = document.querySelectorAll('[data-tracking]');
        for (let i = 0; i < $$tracking.length; i++) {
            const $tracking = $$tracking[i];

            const $trackingAPI = new Tracking($tracking);
            $trackingAPI.initialize();
        }

        // Accordion
        const $$accordion = document.querySelectorAll('[data-accordion="root"]');
        for (let i = 0; i < $$accordion.length; i++) {
            const $accordion = $$accordion[i];

            const $accordionAPI = new Accordion($accordion);
            $accordionAPI.initialize();
        }

        // Circle Animation
        const $$circleAnimation = document.querySelectorAll('[data-circleanimation="root"]');
        for (let i = 0; i < $$circleAnimation.length; i++) {
            const $circleAnimation = $$circleAnimation[i];

            const $circleAnimationAPI = new CircleAnimation($circleAnimation);
            $circleAnimationAPI.initialize();
        }

        // Data Graph
        const $$dataGraph = document.querySelectorAll('[data-datagraph="root"]');
        for (let i = 0; i < $$dataGraph.length; i++) {
            const $dataGraph = $$dataGraph[i];

            const $dataGraphAPI = new DataGraph($dataGraph);
            $dataGraphAPI.initialize();
        }

        // Home Stage
        const $$homeStage = document.querySelectorAll('[data-homestage="root"]');
        for (let i = 0; i < $$homeStage.length; i++) {
            const $homeStage = $$homeStage[i];

            const $homeStageAPI = new HomeStage($homeStage);
            $homeStageAPI.initialize();
        }

        // Map
        const $$map = document.querySelectorAll('[data-map="root"]');
        for (let i = 0; i < $$map.length; i++) {
            const $map = $$map[i];

            const $mapAPI = new Map($map);
            $mapAPI.initialize();
        }

        // Pillars
        const $$pillars = document.querySelectorAll('[data-pillars="root"]');
        for (let i = 0; i < $$pillars.length; i++) {
            const $pillars = $$pillars[i];

            const $pillarsAPI = new Pillars($pillars);
            $pillarsAPI.initialize();
        }

        // StoryStage
        const $$storyStage = document.querySelectorAll('[data-storystage="root"]');
        for (let i = 0; i < $$storyStage.length; i++) {
            const $storystage = $$storyStage[i];

            const $storystageAPI = new StoryStage($storystage);
            $storystageAPI.initialize();
        }

        // ShareTeaser
        const $$shareTeaser = document.querySelectorAll('[data-shareteaser="root"]');
        for (let i = 0; i < $$shareTeaser.length; i++) {
            const $shareteaser = $$shareTeaser[i];

            const $shareteaserAPI = new ShareTeaser($shareteaser);
            $shareteaserAPI.initialize();
        }

        // Text Image Slider
        const $$textImageSlider = document.querySelectorAll('[data-textimageslider="root"]');
        for (let i = 0; i < $$textImageSlider.length; i++) {
            const $textimageslider = $$textImageSlider[i];

            const $textimagesliderAPI = new TextImageSlider($textimageslider);
            $textimagesliderAPI.initialize();
        }

        // Videoplayer
        const $$videoplayer = document.querySelectorAll('[data-videoplayer="root"]');

        if ($$videoplayer.length > 0) {
            window.onYouTubeIframeAPIReady = () => {
                console.log('iframe api ready, init module');
                for (let i = 0; i < $$videoplayer.length; i++) {
                    const $videoplayer = $$videoplayer[i];

                    const videoplayerAPI = new Videoplayer($videoplayer);
                    $videoplayer.API = videoplayerAPI;
                }
            };
        }

        window.setTimeout(() => {
            checkInview();
        }, 750);

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        document.body.classList.add('page--loaded');

        window.addEventListener('beforeunload', () => {
            const isProtocolLink = document.activeElement.protocol === 'mailto:' || document.activeElement.protocol === 'tel:';

            if (!isProtocolLink) {
                document.body.classList.remove('page--loaded');
            }
        });
    };

    initApplication();
});
